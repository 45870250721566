import { Dispatch } from "redux";
import { RootState, closeModal} from "../../../store";
import { MODAL_TYPE } from "../../../utils";
import { EditProject } from "./EditProject";
import { connect } from "react-redux";
import { updateCanFetchContainers } from "../../../store/file-folder-upload/slice";

type StateProps = {
  visibility: boolean;
};

type DispatchProps = {
  handleClose: () => void;
  handleCanFetchContainers: () => void;
};

export type AddContainerProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => ({
  visibility: state.ui.modal[MODAL_TYPE.EDIT_PROJECT],
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleClose: () => {
    dispatch(closeModal(MODAL_TYPE.EDIT_PROJECT));
  },
  handleCanFetchContainers: () => {
    dispatch(updateCanFetchContainers(true));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProject);
