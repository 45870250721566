import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { MODAL_TYPE } from "../../utils";
import AddProject from "./add-project";
import './modal.scss'
import DeleteConfirmation from "./delete-confirmation";
import EditProject from "./edit-project";
import UpdateCarePlan from "./update-care-plan";

export const Modal = () => {
  const { modal } = useSelector((state: RootState) => state.ui);
  
  return (
    <>
      {modal[MODAL_TYPE.ADD_PROJECT] && <AddProject />}
      {modal[MODAL_TYPE.EDIT_PROJECT] && <EditProject />}
      {modal[MODAL_TYPE.DELETE_CONFIRMATION] && <DeleteConfirmation />}
      {modal[MODAL_TYPE.UPDATE_CARE_PLAN] && <UpdateCarePlan />}
    </>
  );
};
