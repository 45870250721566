import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import "./member-profile.scss";
import { MemberDetail, projectService } from "../../api/project";
import { Loading } from "../../components";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

interface Props {
  isMemberProfileVisible: boolean;
}

const MemberProfile = ({ isMemberProfileVisible }: Props) => {
  const [memberData, setMemberData] = useState<MemberDetail>();
  const [loading, setLoading] = useState(false);
  const { selectedCaseId } = useSelector((state: RootState) => state.projectDetails);
  useEffect(() => {
    const fetchMemberDetails = async () => {
      try {
        setLoading(true);
        const response = await projectService.getMemberDetails(selectedCaseId);
        setMemberData(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching member details:", error);
        setLoading(false);
      }
    };

    fetchMemberDetails();
  }, []);

  return isMemberProfileVisible ? (
    !loading ? (
      <div className="member-profile-container">
        <h2 className="member-title">Member Profile</h2>
        <Divider className="custom-divider" />
        <div className="member-flex">
          <p className="member-profile-heading">Name</p>
          <span className="member-profile-value">{memberData?.member_details.member_name}</span>
        </div>
        <Divider className="custom-divider" />
        <div className="member-flex">
          <p className="member-profile-heading">Gender</p>
          <span className="member-profile-value">{memberData?.member_details.gender}</span>
        </div>
        <Divider className="custom-divider" />
        <div className="member-flex">
          <p className="member-profile-heading">Member ID</p>
          <span className="member-profile-value">{memberData?.member_details.member_id}</span>
        </div>
        <Divider className="custom-divider" />
        <div className="member-flex">
          <p className="member-profile-heading">Date of Birth</p>
          <span className="member-profile-value">{memberData?.member_details.date_of_birth}</span>
        </div>
        <Divider className="custom-divider" />
        <div className="member-flex">
          <p className="member-profile-heading">Contact Number</p>
          <span className="member-profile-value">{memberData?.member_details.contact_no}</span>
        </div>
        <Divider className="custom-divider" />
        <div>
          <p className="member-profile-heading">Mailing Address</p>
          <span className="member-profile-value">{memberData?.member_details.address}</span>
        </div>
        <Divider className="custom-divider" />
        <div>
          <p className="member-profile-heading">Case Manager Name</p>
          <span className="member-profile-value">{memberData?.manager_name}</span>
        </div>
        <Divider className="custom-divider" />
        <div>
          <p className="member-profile-heading">PCP Name</p>
          <span className="member-profile-value">{memberData?.pcp_name}</span>
        </div>
      </div>
    ) : (
      <div className="member-profile-container">
        <Loading />
      </div>
    )
  ) : (
    <></>
  );
};

export default MemberProfile;
