import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Switch } from "antd";
import { DeleteIcon, EditIcon } from "../../assets/icons";
import { openModal, updateProjectDetails } from "../../store";
import { MODAL_TYPE } from "../../utils";

interface Props {
  project: IProject;
  setProjectToBeDeleted: React.Dispatch<React.SetStateAction<number>>;
}

export const ProjectActions = ({ project, setProjectToBeDeleted }: Props) => {
  const [confirmModal, setConfirmModal] = useState(false);
  const dispatch = useDispatch();
  // const onChange = async (status: boolean) => {
  //   try {
  //     const { data } = await projectService.disableEnable(
  //       project.OrganizationId,
  //       status,
  //       Number(project.Id)
  //     );
  //     dispatch(updateProject(data.data));
  //   } catch (err: any) {
  //     openNotificationWithIcon("", err.response.data.message, "error");
  //   }
  // };
  const handleDeleteProject = async () => {
    try {
      // dispatch(deleteProject(project.Id))
      // const res = await projectService.deleteOne(clientId,project.Id);
      setConfirmModal(false);
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };
  return (
    <div className="editDelete flex gp">
      {/* <Link to={`${PAGE_URL.PROJECT_DETAIL}/${project.Id}`}>View More</Link> */}
      <div
        onClick={(event: any) => {
          event.stopPropagation();
          dispatch(updateProjectDetails(project));
          dispatch(openModal(MODAL_TYPE.EDIT_PROJECT));
        }}
      >
        <EditIcon />
      </div>
      <div
        onClick={(event: any) => {
          event.stopPropagation();
          setProjectToBeDeleted(project.id)
          dispatch(updateProjectDetails(project));
          dispatch(openModal(MODAL_TYPE.DELETE_CONFIRMATION));
        }}
      >
        <DeleteIcon />
      </div>
      {/* {confirmModal && <DeleteConfirmation visibility={confirmModal} handleClose={()=>{setConfirmModal(false)}} handleDelete={handleDeleteProject}/>} */}
    </div>
  );
};
