import axios from "axios";
import { IRepsonseWrapper, apiClient } from ".";

interface ProjectWrapper {
  projects: IProject[];
}

interface ProjectDetailResponse {
  project: IProject;
}

export interface Member {
  member_id: string;
  member_name: string;
  pcp_name: string;
}

export interface MemberDetails {
  id: number;
  manager_name: string;
  status: string;
  members: Member;
}

interface MembersDetailsResponse {
  data: MemberDetails[];
  status: string;
}

export interface MemberDetail {
  manager_name: string;
  member_details: {
    member_id: string;
    member_name: string;
    gender: string;
    address: string;
    contact_no: string;
    date_of_birth: string;
  };
  pcp_name: string;
}

interface PCPDetails {
  provider_npi: string;
  provider_name: string;
  service_location: string;
  provider_speciality: string;
  effective_date_with_member: string;
}

interface PCPDetailsData {
  pcp_details: PCPDetails;
}

export interface EligibilityDetails {
  subscriber_id: string;
  health_plan_id: string;
  enrollment_plan: string;
  enrollment_status: string;
  enrollment_effective_date: string;
}

interface EligibilityDetailsData {
  eligibility_details: EligibilityDetails;
}

export interface GetUploadSignedUrl {
  data: {
    signedUrl: string;
    accessToken?: string;
  };
  status: string;
}

const getProjects = async () => {
  const res = await apiClient.get<IRepsonseWrapper<ProjectWrapper>>(`/project`);
  return res;
};

const getProject = async (id?: number) => {
  const res = await apiClient.get<IRepsonseWrapper<ProjectDetailResponse>>(`project/${id}`);
  return res;
};

const deleteProject = async (id: number) => {
  const res = await apiClient.delete<IRepsonseWrapper<IProject>>(`/project/${id}`);
  return res;
};

const createProject = async (projectName: string, projectDescription: string) => {
  const res = await apiClient.post<IRepsonseWrapper<IProject>>(`/project/create`, {
    name: projectName,
    description: projectDescription,
  });
  return res;
};

const editProject = async (id: number, projectName: string, projectDescription: string) => {
  const res = await apiClient.put<IRepsonseWrapper<IProject>>(`/project/${id}`, {
    name: projectName,
    description: projectDescription,
  });
  return res;
};

const getMemberInformation = async (projectId: number, searchTerm: string, orderBy: string) => {
  const res = await apiClient.get<IRepsonseWrapper<MemberDetails[]>>(
    `/cases/list/${projectId}?limit=0&offset=0${searchTerm ? `&searchBy=${searchTerm}` : ""}${
      orderBy ? `&orderBy=${Array.isArray(orderBy) ? orderBy[1] : orderBy}` : ""
    }`
  );
  return res;
};

const getMemberDetails = async (caseId: number) => {
  const res = await apiClient.get<IRepsonseWrapper<any>>(`/cases/member-details/${caseId}`);
  return res;
};

const getPcpDetails = async (caseId: number) => {
  const res = await apiClient.get<IRepsonseWrapper<PCPDetailsData>>(`/cases/pcp-details/${caseId}`);
  return res;
};

const getEligibilityDetails = async (caseId: number) => {
  const res = await apiClient.get<IRepsonseWrapper<EligibilityDetailsData>>(
    `/cases/eligibility-details/${caseId}`
  );
  return res;
};

const getUploadSignedUrl = async (caseId: number, path: string) => {
  const res = await apiClient.get<GetUploadSignedUrl>(
    `/document/signedUrl/upload/${caseId}?path=${path}`
  );
  return res;
};

const handleShowFile = async (path: string, caseID: number) => {
  const res = await apiClient.get(`/document/signedUrl/readById/${caseID}?name=${path}`);
  return res;
};

const uploadFiles = async (url: string, file: any) => {
  const form = new FormData();
  form.append("file", file);
  const res = await axios({
    method: "put",
    url,
    data: form,
    headers: { "Content-Type": `multipart/form-data;`, "x-ms-blob-type": "BlockBlob" },
  });
  return res;
};

const ingestSummary = async (caseId: number, path: string) => {
  const res = await apiClient.post(`document/ingest`, {
    caseId: caseId,
    path: path,
    documentType: "callSummary",
    meta: {
      documentType: "doc",
    },
  });
  return res;
};

const getCallSummary = async (caseId: number) => {
  const res = await apiClient.get(`/document/callSummary/${caseId}`);
  return res;
};

const getPrevDARNotes = async (caseId: number) => {
  const res = await apiClient.get(`/cases/dar-notes/${caseId}`);
  return res;
};

const deleteDocuments = async (docId: string, documentType: string) => {
  const res = await apiClient.delete(`/document/${docId}`, {
    documentType: documentType,
  } as any);
  return res;
};

const getCarePlanVersions = async (caseId: number) => {
  const res = await apiClient.get(`/cases/list/care-plan/${caseId}`);
  return res;
};

const updateDARNote = async (darNoteId: number, darNote: string) => {
  const res = await apiClient.put(`/cases/dar-notes/${darNoteId}`, { darNote: darNote });
  return res;
};

const saveDARNote = async (darnoteId: number) => {
  const res = await apiClient.post(`/cases/dar-notes/${darnoteId}`);
  return res;
};

const updateCarePlan = async (caseId: number) => {
  const res = await apiClient.post(`/cases/generate-care-plan/${caseId}`);
  return res;
};

const approveCarePlanChanges = async (caseId: number, carePlanId: number) => {
  const res = await apiClient.put(`/cases/care-plan/approve/${caseId}`, { carePlanId: carePlanId });
  return res;
}

const fetchReviewCarePlan = async (caseId: number) => {
  const res = await apiClient.get(`/cases/care-plan/list/${caseId}`);
  return res.data.data;
};

const deleteCarePlan = async(carePlanId: string) => {
  const res = await apiClient.delete(`/cases/care-plan/${carePlanId}`);
  return res;
}

export const projectService = {
  getProjects,
  getProject,
  deleteProject,
  createProject,
  getMemberInformation,
  editProject,
  getMemberDetails,
  getPcpDetails,
  getEligibilityDetails,
  getUploadSignedUrl,
  uploadFiles,
  ingestSummary,
  getCallSummary,
  handleShowFile,
  getPrevDARNotes,
  deleteDocuments,
  getCarePlanVersions,
  updateDARNote,
  saveDARNote,
  updateCarePlan,
  fetchReviewCarePlan,
  approveCarePlanChanges,
  deleteCarePlan
};
