import React, { useEffect, useState } from "react";
import { Button, Collapse, Modal, Table, Tabs } from "antd";
import { EligibilityDetails, projectService } from "../../api/project";
import "./precall-review.scss";
import { downloadPDF, getColumnsClaims, getReviewClass } from "./helper";
import { ArrowDownIcon, DeleteIcon, RightArrowIcon } from "../../assets/icons";
import {
  ArrowRightOutlined,
  CloseOutlined,
  DownOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { precallReviewService } from "../../api";
import { MODAL_TYPE, getLocalDateTimeStringFromISO, openNotificationWithIcon } from "../../utils";
import { ReactTyped } from "react-typed";
import { Loading } from "../Loading";
import { PdfViewer } from "../pdf-viewer";
import { useDispatch } from "react-redux";
import { RootState, openModal, updateDeleteConfirmation } from "../../store";
import { useSelector } from "react-redux";
import jsPDF from "jspdf";

const { Panel } = Collapse;
const { TabPane } = Tabs;

interface PCPDetails {
  provider_npi: string;
  provider_name: string;
  service_location: string;
  provider_speciality: string;
  effective_date_with_member: string;
}

interface Props {
  selectedCaseId: number;
}

const initialLoadingState = { generateSummary: false, saveSummary: false, exportSummary: false };

const PrecallReview: React.FC<Props> = ({ selectedCaseId }: Props) => {
  const [pcpDetails, setPcpDetails] = useState({} as PCPDetails);
  const [eligibilyDetails, setEligibilityDetails] = useState({} as EligibilityDetails);
  const [showPrecallSummary, setShowPrecallSummary] = useState(false);
  const [showGenerateActions, setShowGenerateActions] = useState(true);
  const [loading, setLoading] = useState(initialLoadingState);
  const [assessmentDocSummary, setAssessmentDocSummary] = useState("");
  const [clinicalDocSummary, setClinicalDocSummary] = useState("");
  const [miniSummary, setMiniSummary] = useState("");
  const [assessment, setAssessment] = useState([]);
  const [clinicalInfo, setClinicalInfo] = useState([]);
  const [showFile, setShowFile] = useState(false);
  const [showFileName, setShowFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [reviewId, setReviewId] = useState(0);
  const [isAssessmentDocSummaryTyped, setIsAssessmentDocSummaryTyped] = useState(false);
  const [isClinicalDocSummaryTyped, setIsClinicalDocSummaryTyped] = useState(false);
  const [isMiniSummaryTyped, setIsMiniSummaryTyped] = useState(false);
  const [deleteAssessment, setDeleteAssessment] = useState("");
  const [deleteClinicalInfo, setDeleteClinicalInfo] = useState("");
  const [button, setButton] = useState("showGenerateActions");

  const dispatch = useDispatch();
  const { isDeleteConfirmed } = useSelector((state: RootState) => state.ui);

  const fetchAssessment = async () => {
    const {
      data: { assessments },
    } = await precallReviewService.getAssessment(selectedCaseId);
    setAssessment(assessments);
  };
  const fetchClinicalInfo = async () => {
    const data = await precallReviewService.getClinicalInfo(selectedCaseId);
    setClinicalInfo(data.clinicalInfo);
  };

  useEffect(() => {
    const handleGetPcpDetails = async () => {
      const { data } = await projectService.getPcpDetails(selectedCaseId);
      setPcpDetails(data.data.pcp_details);
    };
    const handleGetEligibilityInformation = async () => {
      const { data } = await projectService.getEligibilityDetails(selectedCaseId);
      setEligibilityDetails(data.data.eligibility_details);
    };
    if (selectedCaseId) {
      handleGetEligibilityInformation();
      handleGetPcpDetails();
      fetchAssessment();
      fetchClinicalInfo();
    } else {
      openNotificationWithIcon("", "No case id", "error");
    }
  }, []);

  useEffect(() => {
    const handleDeleteDocuments = async () => {
      await projectService.deleteDocuments(deleteAssessment, "assessment");
      fetchAssessment();
      setDeleteAssessment("");
    };
    if (deleteAssessment !== "" && isDeleteConfirmed) {
      handleDeleteDocuments();
      dispatch(updateDeleteConfirmation(false));
    }
  }, [deleteAssessment, dispatch, isDeleteConfirmed]);

  useEffect(() => {
    const handleDeleteDocuments = async () => {
      await projectService.deleteDocuments(deleteClinicalInfo, "clinicalInfo");
      fetchClinicalInfo();
      setDeleteClinicalInfo("");
    };
    if (deleteClinicalInfo !== "" && isDeleteConfirmed) {
      handleDeleteDocuments();
      dispatch(updateDeleteConfirmation(false));
    }
  }, [deleteClinicalInfo, dispatch, isDeleteConfirmed]);

  const handleClose = () => {
    setShowFileName("");
    setShowFile(false);
  };

  useEffect(() => {
    const getSignedurl = async () => {
      setIsLoading(true);
      try {
        const { data } = await projectService.handleShowFile(showFileName, selectedCaseId);
        setFileUrl(data.data.signedUrl);
      } catch (err: any) {
        openNotificationWithIcon("", err.response.data.message, "error");
        handleClose();
      } finally {
        setIsLoading(false);
      }
    };
    if (showFile && showFileName) getSignedurl();
  }, [showFile, showFileName]);

  const setLoadingHelper = (key: keyof typeof initialLoadingState) => {
    setLoading((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const handleShowFile = async (fileName: string) => {
    setShowFile(true);
    setShowFileName(fileName);
  };

  const getColumnsClinicalInformation = () => [
    {
      title: "Document Type",
      key: "documentType",
      dataIndex: "documentType",
    },
    {
      title: "Name",
      key: "document.name",
      dataIndex: "name",
      render: (text: any, value: any) => value.document.name,
    },
    {
      title: "Date Created",
      key: "document.createdAt",
      dataIndex: "document.createdAt",
      render: (text: any, value: any) => getLocalDateTimeStringFromISO(value.document.createdAt),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: any, value: any) => (
        <div className="status" style={{ color: getReviewClass(value.document.status) }}>
          <p style={{ color: getReviewClass(value.document.status) }}>{value.document.status}</p>{" "}
        </div>
      ),
    },
    {
      title: "Report",
      dataIndex: "report",
      key: "report",
      render: (text: any, value: any) => (
        <div
          className="link-color cursor-pointer"
          onClick={() =>
            handleShowFile(value.document.name.substring(value.document.name.lastIndexOf("/") + 1))
          }
        >
          View Note
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text: any, value: any) => (
        <a className="p-10">
          <DeleteIcon
            width={18}
            height={18}
            onClick={() => {
              setDeleteClinicalInfo(value.document.uuid);
              dispatch(openModal(MODAL_TYPE.DELETE_CONFIRMATION));
            }}
          />
        </a>
      ),
      className: "table-action",
    },
  ];

  const getColumnsAssessment = () => [
    {
      title: "Category",
      key: "category",
      dataIndex: "category",
    },
    {
      title: "Name",
      key: "document.name",
      dataIndex: "name",
      render: (text: any, value: any) => value.document.name,
    },
    {
      title: "Date Created",
      key: "document.createdAt",
      dataIndex: "document.createdAt",
      render: (text: any, value: any) => getLocalDateTimeStringFromISO(value.document.createdAt),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: any, value: any) => (
        <div className="status" style={{ color: getReviewClass(value.document.status) }}>
          <p style={{ color: getReviewClass(value.document.status) }}>{value.document.status}</p>{" "}
        </div>
      ),
    },
    {
      title: "Report",
      dataIndex: "report",
      key: "report",
      render: (text: any, value: any) => (
        <div
          className="link-color cursor-pointer"
          onClick={() =>
            handleShowFile(value.document.name.substring(value.document.name.lastIndexOf("/") + 1))
          }
        >
          View Report
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text: any, value: any) => (
        <a className="p-10">
          <DeleteIcon
            width={18}
            height={18}
            onClick={() => {
              setDeleteAssessment(value.document.uuid);
              dispatch(openModal(MODAL_TYPE.DELETE_CONFIRMATION));
            }}
          />
        </a>
      ),
      className: "table-action",
    },
  ];

  const handleFileDownload = async () => {
    await downloadPDF(fileUrl, `${showFileName}_${new Date().getTime()}.pdf`);
  };

  const handleAssessmentDocSummaryTyped = () => {
    setIsAssessmentDocSummaryTyped(true);
  };

  const handleClinicalDocSummaryTyped = () => {
    setIsClinicalDocSummaryTyped(true);
  };

  const handleMiniSummaryTyped = () => {
    setIsMiniSummaryTyped(true);
  };

  const exportSummaryToPDF = () => {
    const doc = new jsPDF({
      orientation: 'portrait', // Set orientation to portrait
      unit: 'mm', // Set units to millimeters
      format: 'a4', // Set format to A4
    });
  
    const content = `
Mini Summary:
${miniSummary.trim()}

Assessment Doc Summary:
${assessmentDocSummary.trim()}

Clinical Doc Summary:
${clinicalDocSummary.trim()}
    `;
  
    const splitText = doc.splitTextToSize(content, 230); // Adjust width as needed
  
    doc.setFontSize(16);
    doc.text("Pre-call Review Summary", 10, 10);
    doc.setFontSize(12);
  
    // Function to add page with header
    const addPageWithHeader = () => {
      doc.addPage();
    };
  
    let yPosition = 20; // Initial Y position for text
  
    // Iterate through split text and add to PDF
    splitText.forEach((text: any, index: any) => {
      const textHeight = doc.getTextDimensions(text).h;
      // Check if adding this text would exceed the page height
      if (yPosition + textHeight > doc.internal.pageSize.height - 10) {
        // Add a new page with header
        addPageWithHeader();
        // Reset Y position
        yPosition = 20;
      }
      doc.text(text, 10, yPosition);
      yPosition += textHeight + 5; // Increment Y position
    });
  
    doc.save("precall_summary.pdf");
  };
  
  const renderPrecallReview = () => {
    if (button === "showGenerateActions") {
      return (
        <div className="precall-review__data__summary">
          {miniSummary && assessmentDocSummary && clinicalDocSummary && (
            <div className="content">
              {miniSummary !== "" && (
                <div>
                  <div>
                    {!isMiniSummaryTyped && (
                      <div key={miniSummary} className="single-info">
                        <h3>Member Profile Overview</h3>
                        <ReactTyped
                          strings={[miniSummary.replace(/&/g, "&amp;")]}
                          typeSpeed={0}
                          showCursor={false}
                          onComplete={handleMiniSummaryTyped}
                        />
                      </div>
                    )}
                    {isMiniSummaryTyped && (
                      <div className="single-info">
                        <h3>Member Profile Overview</h3>
                        {miniSummary}
                      </div>
                    )}
                  </div>
                  <div>
                    {isMiniSummaryTyped && !isAssessmentDocSummaryTyped && (
                      <div key={assessmentDocSummary} className="single-info">
                        <h3>Assessment Surveys</h3>
                        <ReactTyped
                          strings={[assessmentDocSummary.replace(/&/g, "&amp;")]}
                          typeSpeed={0}
                          showCursor={false}
                          onComplete={handleAssessmentDocSummaryTyped}
                        />
                      </div>
                    )}
                    {isAssessmentDocSummaryTyped && (
                      <div className="single-info">
                        <h3>Assessment Surveys</h3>
                        {assessmentDocSummary}
                      </div>
                    )}
                  </div>
                  <div>
                    {isAssessmentDocSummaryTyped && !isClinicalDocSummaryTyped && (
                      <div key={clinicalDocSummary} className="single-info">
                        <h3>Clinical Information</h3>
                        <ReactTyped
                          strings={[clinicalDocSummary.replace(/&/g, "&amp;")]}
                          typeSpeed={0}
                          showCursor={false}
                          onComplete={handleClinicalDocSummaryTyped}
                        />
                      </div>
                    )}
                    {isClinicalDocSummaryTyped && (
                      <div className="single-info">
                        <h3>Clinical Information</h3>
                        {clinicalDocSummary}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      );
    }
    return (
      <div className="collapse">
        <div className="collapse-eligibility-pcp grid grid-cols-2 gp">
          <Collapse
            expandIconPosition="right"
            expandIcon={({ isActive }) =>
              isActive ? (
                <DownOutlined style={{ fontSize: "16px", color: "#3232A6", opacity: 1 }} />
              ) : (
                <RightOutlined style={{ fontSize: "16px", color: "#3232A6", opacity: 1 }} />
              )
            }
          >
            <Panel header="Eligibility Information" key="1">
              <ul>
                {/* {Object.entries(eligibilyDetails).map(([key, value]) => (
              <li key={key}>
                <div className="precall-custom-short-collapse grid grid-cols-2 border-bottom">
                  <p className="label fw-600">{key.replace(/_/g, " ").toUpperCase()}</p>
                  <p style={{ padding: "0px 30px" }}>{value}</p>
                </div>
              </li>
            ))} */}
                <li>
                  <div className="precall-custom-short-collapse grid grid-cols-2 border-bottom">
                    <p className="label fw-600">Enrollment Plan</p>
                    <p style={{ padding: "0px 30px" }}>{eligibilyDetails.enrollment_plan}</p>
                  </div>
                </li>
                <li>
                  <div className="precall-custom-short-collapse grid grid-cols-2 border-bottom">
                    <p className="label fw-600">Enrollment Status</p>
                    <p style={{ padding: "0px 30px" }}>{eligibilyDetails.enrollment_status}</p>
                  </div>
                </li>
                <li>
                  <div className="precall-custom-short-collapse grid grid-cols-2 border-bottom">
                    <p className="label fw-600">Enrollment Effective Date</p>
                    <p style={{ padding: "0px 30px" }}>
                      {eligibilyDetails.enrollment_effective_date}
                    </p>
                  </div>
                </li>
                <li>
                  <div className="precall-custom-short-collapse grid grid-cols-2 border-bottom">
                    <p className="label fw-600">Health Plan ID</p>
                    <p style={{ padding: "0px 30px" }}>{eligibilyDetails.health_plan_id}</p>
                  </div>
                </li>
                <li>
                  <div className="precall-custom-short-collapse grid grid-cols-2 border-bottom">
                    <p className="label fw-600">Subscriber ID</p>
                    <p style={{ padding: "0px 30px" }}>{eligibilyDetails.subscriber_id}</p>
                  </div>
                </li>
              </ul>
            </Panel>
          </Collapse>
          <Collapse
            expandIconPosition="right"
            expandIcon={({ isActive }) =>
              isActive ? (
                <DownOutlined style={{ fontSize: "16px", color: "#3232A6", opacity: 1 }} />
              ) : (
                <RightOutlined style={{ fontSize: "16px", color: "#3232A6", opacity: 1 }} />
              )
            }
          >
            <Panel header="PCP Information" key="2">
              <ul>
                {/* {Object.entries(pcpDetails).map(([key, value]) => (
                  <li key={key}>
                    <div className="precall-custom-short-collapse grid grid-cols-2 border-bottom">
                      <p className="label fw-600">{key.replace(/_/g, " ").toUpperCase()}</p>
                      <p style={{ padding: "0px 30px" }}>{value}</p>
                    </div>
                  </li>
                ))} */}
                <li>
                  <div className="precall-custom-short-collapse grid grid-cols-2 border-bottom">
                    <p className="label fw-600">Provider Name</p>
                    <p style={{ padding: "0px 30px" }}>{pcpDetails.provider_name}</p>
                  </div>
                </li>
                <li>
                  <div className="precall-custom-short-collapse grid grid-cols-2 border-bottom">
                    <p className="label fw-600">Provider NPI</p>
                    <p style={{ padding: "0px 30px" }}>{pcpDetails.provider_npi}</p>
                  </div>
                </li>
                <li>
                  <div className="precall-custom-short-collapse grid grid-cols-2 border-bottom">
                    <p className="label fw-600">Provider Speciality</p>
                    <p style={{ padding: "0px 30px" }}>{pcpDetails.provider_speciality}</p>
                  </div>
                </li>
                <li>
                  <div className="precall-custom-short-collapse grid grid-cols-2 border-bottom">
                    <p className="label fw-600">Effective Date with Member</p>
                    <p style={{ padding: "0px 30px" }}>{pcpDetails.effective_date_with_member}</p>
                  </div>
                </li>
                <li>
                  <div className="precall-custom-short-collapse grid grid-cols-2 border-bottom">
                    <p className="label fw-600">Service Location</p>
                    <p style={{ padding: "0px 30px" }}>{pcpDetails.service_location}</p>
                  </div>
                </li>
              </ul>
            </Panel>
          </Collapse>
        </div>
        <div className="precall-review-lg-collapse">
          <Collapse
            expandIconPosition="right"
            expandIcon={({ isActive }) =>
              isActive ? (
                <DownOutlined style={{ fontSize: "16px", color: "#3232A6", opacity: 1 }} />
              ) : (
                <RightOutlined style={{ fontSize: "16px", color: "#3232A6", opacity: 1 }} />
              )
            }
          >
            <Panel header="Assessment" key="3">
              <Table columns={getColumnsAssessment()} pagination={false} dataSource={assessment} />
            </Panel>
          </Collapse>
          <Collapse
            expandIconPosition="right"
            expandIcon={({ isActive }) =>
              isActive ? (
                <DownOutlined style={{ fontSize: "16px", color: "#3232A6", opacity: 1 }} />
              ) : (
                <RightOutlined style={{ fontSize: "16px", color: "#3232A6", opacity: 1 }} />
              )
            }
          >
            <Panel header="Claims" key="4">
              <Table
                columns={getColumnsClaims()}
                pagination={false}
                dataSource={[
                  {
                    claimId: "1202045764",
                    status: "Paid",
                    provider: "Josephine Medical Center",
                    billedAmount: "$204.5",
                    eligibleAmount: "$204.5",
                  },
                  {
                    claimId: "2302045764",
                    status: "Paid",
                    provider: "Josephine Medical Center",
                    billedAmount: "$565.0",
                    eligibleAmount: "$565.0",
                  },
                ]}
              />
            </Panel>
          </Collapse>
          <Collapse
            expandIconPosition="right"
            expandIcon={({ isActive }) =>
              isActive ? (
                <DownOutlined style={{ fontSize: "16px", color: "#3232A6", opacity: 1 }} />
              ) : (
                <RightOutlined style={{ fontSize: "16px", color: "#3232A6", opacity: 1 }} />
              )
            }
          >
            <Panel header="Clinical Information" key="5">
              <Table
                columns={getColumnsClinicalInformation()}
                pagination={false}
                dataSource={clinicalInfo}
              />
            </Panel>
          </Collapse>
        </div>
      </div>
    );
  };

  const handleGenerateReview = async () => {
    setLoadingHelper("generateSummary");
    try {
      const { data } = await precallReviewService.generateSummary({}, selectedCaseId);
      setAssessmentDocSummary(data.content.assessment_doc_summary);
      setClinicalDocSummary(data.content.clinical_doc_summary);
      setMiniSummary(data.content.mini_summary);
      setReviewId(data.id);
      const antTabs = document.querySelector(".ant-tabs-nav-list");
      antTabs && antTabs.scrollIntoView({ behavior: "smooth", block: "start" });
      setShowGenerateActions(false);
      setShowPrecallSummary(true);
    } catch (error: any) {
      openNotificationWithIcon("", error.data.message, "error");
    } finally {
      setLoadingHelper("generateSummary");
    }
  };

  const handleExportSummary = async () => {
    setLoadingHelper("exportSummary");
    try {
      // const data = await precallReviewService.generateSummary({});
      exportSummaryToPDF();
      setShowGenerateActions(true);
      setShowPrecallSummary(false);
    } catch (error: any) {
      openNotificationWithIcon("", error.data.message, "error");
    } finally {
      setLoadingHelper("exportSummary");
    }
  };

  // const handleSaveSummary = async () => {
  //   setLoadingHelper("saveSummary");
  //   try {
  //     const data = await precallReviewService.savePrecallReview(selectedCaseId, reviewId, {});
  //     setShowGenerateActions(true);
  //     setShowPrecallSummary(false);
  //   } catch (error: any) {
  //     openNotificationWithIcon("", error.data.message, "error");
  //   } finally {
  //     setLoadingHelper("saveSummary");
  //   }
  // };

  const renderPrecallActions = () => {
    if (button === "showGenerateActions") {
      return (
        <>
          {miniSummary === "" ? (
            <Button
              className="fill"
              onClick={() => {
                handleGenerateReview();
              }}
              loading={loading.generateSummary}
            >
              Generate Pre-call review
            </Button>
          ) : (
            <Button
              className="outline"
              onClick={handleExportSummary}
              loading={loading.exportSummary}
            >
              Export Pre-Call Review
            </Button>
          )}
        </>
      );
    }
  };

  return (
    <div className="precall-review">
      <div className="precall-tabs-buttons flex gp-10">
        <Tabs activeKey={button} onChange={(key) => setButton(key)}>
          <TabPane tab="Overview" key="showGenerateActions"></TabPane>
          <TabPane tab="Sources" key="showPrecallSummary"></TabPane>
        </Tabs>
      </div>
      <div className="precall-review__data">{renderPrecallReview()}</div>
      <div className="precall-review__actions">{renderPrecallActions()}</div>
      {showFile && (
        <Modal
          className="autonomizeModal"
          centered
          width={1300}
          visible={showFile}
          footer={null}
          title={"Preview"}
          closeIcon={<CloseOutlined onClick={handleClose} />}
        >
          <div className="addClient-title no-bd-bt">
            <h1 className="mb-0">Preview</h1>
          </div>
          {isLoading ? (
            <Loading />
          ) : (
            <div>
              <div className="addClient-content no-pd-tp">
                <PdfViewer
                  pdf={fileUrl}
                  displayPagination
                  setCurrPage={setCurrentPage}
                  currPage={currentPage}
                  pageInfo={{scale: 2}}
                />
              </div>
              <div className="flex jce p-10">
                <Button className="fill" onClick={handleFileDownload} disabled={fileUrl === ""}>
                  Download
                </Button>
              </div>
            </div>
          )}
        </Modal>
      )}
    </div>
  );
};

export default PrecallReview;
