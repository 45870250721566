import React, { useEffect, useState } from "react";
import { AddContainerProps } from ".";
import { Button, Checkbox, Input, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { InformationIcon } from "../../../assets/icons";
import { projectService } from "../../../api/project";
import {
  openNotificationWithIcon,
} from "../../../utils";

export const AddProject = ({
  handleClose,
  visibility,
  handleCanFetchContainers,
}: AddContainerProps) => {
  const [projectName, setProjectName] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const createContainerButtonClick = async () => {
    setIsLoading(true);
    try {
      await projectService.createProject(
        projectName,
        projectDescription,
      );
      openNotificationWithIcon("", "Project created successfully", "success");
      handleCanFetchContainers();
    } catch (error: any) {
      openNotificationWithIcon("", error.response.data.message, "error");
    } finally {
      setIsLoading(false);
      handleClose();
    }
  };

  return (
    <Modal
      className="autonomizeModal"
      centered
      width={587}
      open={visibility}
      footer={null}
      closable={false}
    >
      <div className="addClient-title no-bd-bt">
        <h1>{"Create Project"}</h1>
        <CloseOutlined onClick={handleClose} style={{ color: "white" }} />
      </div>
      <div className="addClient-content">
        <p className="font-Semibold m-b">Project Name : </p>
        <Input
          className="m-b"
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
        />
        <p className="font-Semibold m-b">Project Description : </p>
        <Input
          className="m-b"
          value={projectDescription}
          onChange={(e) => setProjectDescription(e.target.value)}
        />
        <div className="m-t flex aic gp-10 jcsb">
          <Button
            className="fill"
            onClick={createContainerButtonClick}
            disabled={projectName === ""}
            loading={isLoading}
          >
            Create
          </Button>
        </div>
      </div>
    </Modal>
  );
};
