import React, { useState } from 'react'
import { PageWrapper } from '../../containers';
import CaseDetailContainer from '../../containers/Case _Detail _Container/CaseDetailContainer';
import './case-details.scss'
const CaseDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <PageWrapper  containerClassName='main' loading={isLoading} pageContainerChildren={<CaseDetailContainer />} />
  )
}

export default CaseDetails
