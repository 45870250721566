import { apiClient } from "./apiClient";

const generateSummary = async (data: any, caseId: number) => {
  const response = await apiClient.post(`/cases/generate-precall-review/${caseId}`, data);
  return response.data;
  // return { data: `<strong>Member Profile Overview</strong>
  // John Doe, a male member with the ID 1234, was born on June 9, 2009, making him 14 years old. His registered mailing address is 123 Main Street, Austin, Texas - 78900. Understanding John's background is crucial for personalizing the care and services we offer, ensuring they are age-appropriate and accessible.\n
  // <strong>Eligibility Information</strong>
  // John Doe has been enrolled in our health plan since January 1, 2021. He is currently eligible for all standard pediatric services, including preventive care, emergency services, mental health services, and specialized care as needed. It is essential to verify and update eligibility information regularly to ensure uninterrupted access to care and services.\n
  // <strong>Primary Care Provider (PCP) Information</strong>
  // John's current PCP is Dr. Emily Smith, MD, located at 456 Health Way, Austin, Texas - 78901. Dr. Smith specializes in pediatric care, focusing on developmental health and well-being. She has been John's PCP since his enrollment in our health plan and has a comprehensive understanding of his health history. Coordination with Dr. Smith is vital for managing John's care effectively, especially for referrals and specialized treatments.\n
  // <strong>Assessment Surveys</strong>
  // John has completed several assessment surveys that provide insights into his health status, lifestyle, and environment. The most recent Health Risk Assessment (HRA) indicated a well-balanced diet but revealed a lack of regular physical activity. Additionally, a Social Determinants of Health (SDOH) survey highlighted satisfactory housing and education but identified potential stressors related to social interactions at school.\n
  // <strong>Clinical Information: Progress Notes</strong>
  // Dr. Smith's progress notes over the last year show that John has been generally healthy, with no major illnesses or hospitalizations. However, there have been concerns about his mental health, particularly signs of anxiety and stress related to school. Dr. Smith recommended a consultation with a child psychologist, which took place three months ago. The psychologist's notes suggest mild anxiety managed with cognitive-behavioral therapy (CBT) without the need for medication at this stage. Monitoring and supporting John's mental health remains a priority.\n
  // <strong>Claims Data</strong>
  // Analysis of John's claims data for the past year reveals routine pediatric visits, vaccinations, and two consultations with a child psychologist. There have been no claims for emergency room visits or hospital admissions, indicating no acute health issues. The claims data also show that all preventive care milestones appropriate for John's age have been met. This data is invaluable for tracking health care utilization and identifying areas where intervention may be needed to prevent more serious health issues.
  // `}
};

const getAssessment = async (caseId: number) => {
  const response = await apiClient.get(`/document/assessments/${caseId}`);
  return response.data;
};

const getClinicalInfo = async (caseId: number) => {
  const response = await apiClient.get(`/document/clinicalInfo/${caseId}`);
  return response.data.data;
};

const savePrecallReview = async (caseId: number, reviewId: number, data: any) => {
  const response = await apiClient.post(`/cases/save-precall-review/${caseId}/${reviewId}`, data);
  return response.data;
};

const generateCallSummary = async (caseId: number) => {
  const response = await apiClient.post(`/cases/generate-call-summary/${caseId}`, {});
  return response.data;
};

const generateDARNote = async (caseId: number) => {
  const response = await apiClient.post(`/cases/generate-care-plan/${caseId}`, {});
  return response.data;
};

export const precallReviewService = {
  generateSummary,
  getAssessment,
  getClinicalInfo,
  savePrecallReview,
  generateCallSummary,
  generateDARNote,
};
