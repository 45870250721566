import React, { useEffect, useMemo, useState } from "react";
import { Button, Collapse, Divider, Table, Upload, Tabs, Modal } from "antd";
import "./call-summary.scss";
import { projectService } from "../../api/project";
import { MODAL_TYPE, getLocalDateTimeStringFromISO, openNotificationWithIcon } from "../../utils";
import { CloseOutlined, DownOutlined, RightOutlined } from "@ant-design/icons";
import { Loading } from "../Loading";
import { PdfViewer } from "../pdf-viewer";
import { downloadPDF } from "../precall-review/helper";
import { precallReviewService } from "../../api";
import { ReactTyped } from "react-typed";
import { DeleteIcon } from "../../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  RootState,
  openModal,
  updateDeleteConfirmation,
  updateGeneratedDarNote,
} from "../../store";
import { Input } from "antd";

const { TextArea } = Input;
const { Panel } = Collapse;
const { TabPane } = Tabs;

interface Props {
  selectedCaseId: number;
  navigateToNextTab: (key: string, newCarePlanGenerated?: boolean) => void;
}

interface Document {
  id: number;
  documentType: string;
  isDeleted: boolean;
  document: {
    id: number;
    uuid: string;
    name: string;
    status: string;
    createdAt: string;
    case: {
      id: number;
    };
  };
}

const getReviewClass = (value: string) => {
  switch (value) {
    case "PROCESSING":
      return "#ff9800";
    case "COMPLETED":
      return "#44c658";
    default:
      return "";
  }
};

const CallSummary: React.FC<Props> = ({ selectedCaseId, navigateToNextTab }: Props) => {
  const [button, setButton] = useState("callSummary");
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [showDARNote, setShowDARNote] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [showFile, setShowFile] = useState(false);
  const [showFileName, setShowFileName] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [generatedCallSummary, setGeneratedCallSummary] = useState("");
  const [generatedDARNote, setGeneratedDARNote] = useState("");
  const [prevDarNotes, setPrevDarNotes] = useState([]);
  const [deleteDocument, setDeleteDocument] = useState("");
  const [showReviewCarePlanButton, setShowReviewCarePlanButton] = useState(false);
  const [editDarNote, setEditDarNote] = useState(false);
  const [darnoteId, setDarNoteId] = useState(-1);
  const [isDARNoteTyped, setIsDARNoteTyped] = useState(false);

  const { isDeleteConfirmed } = useSelector((state: RootState) => state.ui);

  const dispatch = useDispatch();

  const getUploads = async () => {
    const { data } = await projectService.getCallSummary(selectedCaseId);
    setDocuments(data.data.callSummary);
    const res = await projectService.getPrevDARNotes(selectedCaseId);
    setPrevDarNotes(res.data.data.darnotes);
    const callSummaryResponse = await precallReviewService.generateCallSummary(selectedCaseId);
    setGeneratedCallSummary(callSummaryResponse.data.callSummary);
    setGeneratedDARNote(callSummaryResponse.data.darnote);
    // setShowDARNote(callSummaryResponse.data.isDarnoteSaved);
    setShowReviewCarePlanButton(callSummaryResponse.data.isDarnoteSaved);
    // setEditDarNote(!callSummaryResponse.data.isDarnoteSaved);
    dispatch(updateGeneratedDarNote(callSummaryResponse.data.darnote));
    setDarNoteId(callSummaryResponse.data.darnoteId);
  };

  useEffect(() => {
    if (selectedCaseId) getUploads();
  }, []);

  useEffect(() => {
    const handleDeleteDocuments = async () => {
      await projectService.deleteDocuments(deleteDocument, "callSummary");
      getUploads();
      setDeleteDocument("");
    };
    if (deleteDocument !== "" && isDeleteConfirmed) {
      handleDeleteDocuments();
      dispatch(updateDeleteConfirmation(false));
    }
  }, [deleteDocument, dispatch, isDeleteConfirmed]);

  const handleFileUpload = async (info: any) => {
    try {
      if (fileUploaded) return;
      const filePath = `${info.file.name}`;
      if (
        filePath.startsWith("HRA_") ||
        filePath.startsWith("hra_") ||
        filePath.startsWith("Call_") ||
        filePath.startsWith("CALL_") ||
        filePath.startsWith("call_")
      ) {
        setFileUploaded(true);
        setLoading(true);
        const { data } = await projectService.getUploadSignedUrl(selectedCaseId, filePath);
        await projectService.uploadFiles(data.data.signedUrl, info.file.originFileObj);
        await projectService.ingestSummary(selectedCaseId, filePath);
        openNotificationWithIcon("", `${info.file.name} uploaded successfully!`, "success");
        setLoading(false);
        getUploads();
        setGeneratedDARNote("");
        setShowDARNote(false);
      } else {
        setFileUploaded(true);
        openNotificationWithIcon("", "Please upload either a HRA file or Call log file only", "error");
      }
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    }
  };

  const handleShowFile = async (fileName: string) => {
    setShowFile(true);
    setShowFileName(fileName);
  };

  const getColumns = () => [
    {
      title: "File Name",
      key: "fileName",
      dataIndex: "fileName",
      render: (text: any, value: any) => (
        <p>{value.fileName.substring(value.fileName.lastIndexOf("/") + 1)}</p>
      ),
    },
    {
      title: "Uploaded Date",
      key: "uploadedDate",
      dataIndex: "uploadedDate",
      render: (text: any, value: any) => <p>{getLocalDateTimeStringFromISO(value.uploadedDate)}</p>,
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (text: any, value: any) => (
        <>
          <div className="status" style={{ color: getReviewClass(value.status) }}>
            <p style={{ color: getReviewClass(value.status) }}>{value.status}</p>{" "}
          </div>
        </>
      ),
    },
    {
      title: "Preview",
      key: "preview",
      dataIndex: "preview",
      render: (text: any, value: any) => (
        <div
          className="link-color cursor-pointer"
          onClick={() =>
            handleShowFile(value.fileName.substring(value.fileName.lastIndexOf("/") + 1))
          }
        >
          Preview
        </div>
      ),
      className: "table-preview",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text: any, value: any) => (
        <a className="p-10">
          <DeleteIcon
            width={18}
            height={18}
            onClick={() => {
              setDeleteDocument(value.docId);
              dispatch(openModal(MODAL_TYPE.DELETE_CONFIRMATION));
            }}
          />
        </a>
      ),
      className: "table-action",
    },
  ];

  const dataSource = useMemo(
    () =>
      documents.map((doc) => ({
        key: doc.id,
        fileName: doc.document.name,
        uploadedDate: doc.document.createdAt,
        status: doc.document.status,
        docId: doc.document.uuid,
      })),
    [documents]
  );

  const dataSourceDARNotes = [
    {
      documentName: "HRA_2024",
      createdDate: "2024-01-13",
    },
    {
      documentName: "CallwithRoager",
      createdDate: "2023-08-13",
    },
  ];

  const handleClose = () => {
    setShowFileName("");
    setShowFile(false);
  };

  const handleFileDownload = async () => {
    await downloadPDF(fileUrl, `uploads_${new Date().getTime()}.pdf`);
  };

  useEffect(() => {
    const getSignedurl = async () => {
      setIsLoading(true);
      try {
        const { data } = await projectService.handleShowFile(showFileName, selectedCaseId);
        setFileUrl(data.data.signedUrl);
      } catch (err: any) {
        openNotificationWithIcon("", err.response.data.message, "error");
        handleClose();
      } finally {
        setIsLoading(false);
      }
    };
    if (showFile && showFileName) getSignedurl();
  }, [showFile, showFileName]);

  // const handleGenerateCallSummary = async () => {
  //   setShowSummary(true);
  //   setLoadGenerateCallSummaryButton(true);
  //   const { data } = await precallReviewService.generateCallSummary(selectedCaseId);
  //   setGeneratedCallSummary(data.callSummary);
  //   setGeneratedDARNote(data.darnote);
  //   dispatch(updateGeneratedDarNote(data.darnote));
  //   setDarNoteId(data.darnoteId);
  //   setLoadGenerateCallSummaryButton(false);
  // };

  const handleGenerateDARNote = async () => {
    setShowDARNote(true);
  };

  const getColumnsDARNotes = () => [
    {
      title: "Document Name",
      dataIndex: "name",
      key: "name",
      render: (text: any, value: any) => (
        <p>{value.name.substring(value.name.lastIndexOf("/") + 1)}</p>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "date",
      key: "date",
      render: (text: any, value: any) => <div>{getLocalDateTimeStringFromISO(value.date)}</div>,
    },
    {
      title: "Preview",
      dataIndex: "preview",
      key: "preview",
      render: (text: any, value: any) => (
        <div
          className="link-color cursor-pointer"
          onClick={() => {
            handleShowFile(value.name.substring(value.name.lastIndexOf("/") + 1));
          }}
        >
          Preview
        </div>
      ),
    },
  ];

  const handleSaveDarNote = async () => {
    try {
      await projectService.saveDARNote(darnoteId);
      openNotificationWithIcon("", "DAR Note saved successfully", "success")
      setShowReviewCarePlanButton(true);
      getUploads();
    } catch (error: any) {
      openNotificationWithIcon("", error.response.data.message, "error");
    }
  };

  const handleUpdateDarNote = async () => {
    if (
      generatedDARNote.includes("Data:") &&
      generatedDARNote.includes("Action:") &&
      generatedDARNote.includes("Response:")
    ) {
      setEditDarNote(false);
      await projectService.updateDARNote(darnoteId, generatedDARNote);
      openNotificationWithIcon("", "DAR Note updated successfully", "success");
    } else {
      openNotificationWithIcon("", "DAR note headers cannot be changed", "error");
    }
  };

  const handleEditDarNote = (event: any) => {
    setGeneratedDARNote(event.target.value);
    dispatch(updateGeneratedDarNote(event.target.value));
  };

  const handleDARNoteTyped = () => {
    setIsDARNoteTyped(true);
  };

  const handleGenerateCarePlan = async () => {
    setIsLoading(true);
    const delay = 3000;
    await projectService.updateCarePlan(selectedCaseId);
    setTimeout(async () => {
      try {
        navigateToNextTab("carePlan");
      } finally {
        setIsLoading(false);
      }
    }, delay);
  };

  return (
    <div>
      <div className="callSummary-buttons flex gp-10">
        <Tabs activeKey={button} onChange={(key) => setButton(key)}>
          <TabPane tab="Call Summarization" key="callSummary"></TabPane>
          <TabPane tab="Previous DAR Notes" key="prevDARNotes"></TabPane>
        </Tabs>
      </div>
      {button === "callSummary" && (
        <div className="collapse">
          <div className="uploads flex jcsb p-10">
            <h3 className="flex-center">Uploads</h3>
            <Upload onChange={handleFileUpload} showUploadList={false}>
              <Button
                className="outline"
                onClick={() => {
                  setFileUploaded(false);
                }}
                loading={loading}
              >
                Upload Conversation
              </Button>
            </Upload>
          </div>
          <Table columns={getColumns()} dataSource={dataSource} pagination={false} />
          {/* {(!showSummary || !generatedCallSummary) && (
            <div className="flex jce p-10">
              <Button
                className="fill"
                onClick={handleGenerateCallSummary}
                loading={loadGenerateCallSummaryButton}
              >
                Generate Call Summary
              </Button>
            </div>
          )} */}
          <div className="collapse precall-review-lg-collapse">
            {
              <Collapse
                expandIconPosition="right"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <DownOutlined
                      style={{
                        fontSize: "16px",
                        color: "#3232A6",
                        opacity: 1,
                        transform: "none",
                      }}
                    />
                  ) : (
                    <RightOutlined
                      style={{
                        fontSize: "16px",
                        color: "#3232A6",
                        opacity: 1,
                        transform: "none",
                      }}
                    />
                  )
                }
              >
                <Panel header="Call Summary" key="1">
                  <div style={{ whiteSpace: "pre-line", maxHeight: "300px" }}>
                    {generatedCallSummary ? <p>{generatedCallSummary}</p> : <Loading />}
                  </div>
                </Panel>
              </Collapse>
            }
            {!showDARNote && (
              <div className="flex jce p-10">
                <Button
                  className="fill"
                  onClick={handleGenerateDARNote}
                  loading={generatedCallSummary === ""}
                >
                  Generate DAR Note
                </Button>
              </div>
            )}
          </div>
          {showDARNote && generatedDARNote && (
            <div className="collapse precall-review-lg-collapse">
              <Collapse
                expandIconPosition="right"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <DownOutlined style={{ fontSize: "16px", color: "#3232A6", opacity: 1 }} />
                  ) : (
                    <RightOutlined style={{ fontSize: "16px", color: "#3232A6", opacity: 1 }} />
                  )
                }
                defaultActiveKey={[1]}
              >
                <Panel header="DAR Note" key="1">
                  {editDarNote ? (
                    <TextArea
                      value={generatedDARNote}
                      style={{ height: 600 }}
                      onChange={handleEditDarNote}
                    />
                  ) : (
                    <div style={{ whiteSpace: "pre-line", maxHeight: "300px" }}>
                      {isDARNoteTyped ? (
                        <p>{generatedDARNote}</p>
                      ) : (
                        <ReactTyped
                          strings={[generatedDARNote.replace(/&/g, "&amp;")]}
                          typeSpeed={1}
                          showCursor={false}
                          onComplete={handleDARNoteTyped}
                        />
                      )}
                    </div>
                  )}
                </Panel>
              </Collapse>
              {!showDARNote && (
                <div className="flex jce p-10">
                  <Button className="fill" onClick={() => setShowDARNote(true)}>
                    Generate DAR Note
                  </Button>
                </div>
              )}
              {showDARNote && (
                <div className="flex jce gp-10 p-10">
                  
                    {(editDarNote ? (
                      <>
                        <Button
                          className="outline"
                          onClick={() => {
                            setShowDARNote(true);
                            setEditDarNote(false);
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="outline"
                          onClick={() => {
                            handleUpdateDarNote();
                            setShowDARNote(true);
                            setEditDarNote(false);
                          }}
                        >
                          Update
                        </Button>
                      </>
                    ) : (
                      <Button
                        className="outline"
                        onClick={() => {
                          setShowDARNote(true);
                          setEditDarNote(true);
                        }}
                      >
                        Edit DAR Note
                      </Button>
                    ))}
                    <Button className="fill" onClick={handleSaveDarNote}>
                      Save DAR note
                    </Button>
                  {showReviewCarePlanButton && (
                    <Button className="fill" onClick={handleGenerateCarePlan} loading={isLoading}>
                      Review Care Plan
                    </Button>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {button === "prevDARNotes" && (
        <div className="p-block">
          <h3 className="p-block">DAR Note Overview</h3>
          <Table
            columns={getColumnsDARNotes()}
            dataSource={prevDarNotes}
            pagination={false}
            className="p-block"
            loading={loading}
          />
        </div>
      )}
      {showFile && (
        <Modal
          className="autonomizeModal"
          centered
          width={1300}
          visible={showFile}
          footer={null}
          title={"Preview"}
          closeIcon={<CloseOutlined onClick={handleClose} />}
        >
          <div className="addClient-title no-bd-bt">
            <h1 className="mb-0">Preview</h1>
          </div>
          {isLoading ? (
            <Loading />
          ) : (
            <div>
              <div className="addClient-content no-pd-tp">
                <PdfViewer
                  pdf={fileUrl}
                  displayPagination
                  setCurrPage={setCurrentPage}
                  currPage={currentPage}
                  pageInfo={{scale: 2}}
                />
              </div>
              <div className="flex jce p-10">
                <Button className="fill" onClick={handleFileDownload} disabled={fileUrl === ""}>
                  Download
                </Button>
              </div>
            </div>
          )}
        </Modal>
      )}
    </div>
  );
};

export default CallSummary;
