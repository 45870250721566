import React, { useState } from "react";
import { PageWrapper } from "../../containers";
import MembersListing from "../../containers/members-listing/MembersListing";

export const Project = () => {

  return <PageWrapper pageContainerChildren={<MembersListing />} />;
};

export default Project;
