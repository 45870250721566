import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ProjectDetailsSlice {
  selectedProjectDetails: IProject;
  selectedCaseId: number;
  generatedDarNote: string;
}
export const projectDetailsInitialState: ProjectDetailsSlice = {
  selectedProjectDetails: {} as IProject,
  selectedCaseId: {} as number,
  generatedDarNote: "" as string,
};

export const projectDetailsSlice = createSlice({
  name: "selectedProjectDetails",
  initialState: projectDetailsInitialState,
  reducers: {
    updateProjectDetails: (state: ProjectDetailsSlice, { payload }: PayloadAction<IProject>) => {
      state.selectedProjectDetails = payload;
    },
    updateSelectedCaseId: (state: ProjectDetailsSlice, { payload }: PayloadAction<number>) => {
      state.selectedCaseId = payload;
    },
    updateGeneratedDarNote: (state: ProjectDetailsSlice, { payload }: PayloadAction<any>) => {
      state.generatedDarNote = payload;
    },
  },
});

export const { updateProjectDetails, updateSelectedCaseId, updateGeneratedDarNote } =
  projectDetailsSlice.actions;

export default projectDetailsSlice.reducer;
