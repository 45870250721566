import React, { useEffect, useState } from "react";
import { AppPagination, Loading } from "../../components";
import { usePagination } from "../../hooks";
import { Button, Table } from "antd";
import { CASE_STATUS, openNotificationWithIcon } from "../../utils";
import { Member, MemberDetails, projectService } from "../../api/project";
import Search from "antd/lib/input/Search";
import { SendOutlined } from "@ant-design/icons";
import { SearchIcon } from "../../assets/icons";
import "../../assets/sass/layout/search.scss";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState, updateProjectDetails, updateSelectedCaseId } from "../../store";
import "./members-listing.scss";

const MembersListing = () => {
  const [memberListing, setMemberListing] = useState<MemberDetails[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const { onPaginationChange, page, pageSize, pageSizeOptions } = usePagination();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const { selectedProjectDetails } = useSelector((state: RootState) => state.projectDetails);
  const handleSearch = (event: any) => {
    setSearchTerm(searchText);
  };
  const [orderBy, setOrderBy] = useState("");

  const getReviewClass = (value: string) => {
    switch (value) {
      case CASE_STATUS.CLOSED:
        return "#ff9800";
      case CASE_STATUS.OPEN:
        return "#44c658";
    }
  };

  const getColumns = () => [
    {
      title: "Member ID",
      key: "memberId",
      dataIndex: ["members", "member_id"], // Update dataIndex to access nested property
      sorter: true,
    },
    {
      title: "Member Name",
      key: "memberName",
      dataIndex: ["members", "member_name"],
      sorter: true,
    },
    {
      title: "Case Manager Name",
      key: "caseManagerName",
      dataIndex: "manager_name",
      sorter: true,
    },
    {
      title: "PCP Name",
      key: "pcpName",
      dataIndex: ["members", "pcp_name"],
      sorter: true,
    },
    {
      title: "Case Status",
      dataIndex: "status",
      className: "dashboard-table-mid-size",
      key: "status",
      render: (text: any, value: any) => (
        <>
          <div className="status" style={{ color: getReviewClass(value.status) }}>
            <p style={{ color: getReviewClass(value.status) }}>{value.status}</p>{" "}
          </div>
        </>
      ),
    },
  ];

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.order === "descend") {
      const field = Array.isArray(sorter.field) ? `-${sorter.field[1]}` : `-${sorter.field}`;
      setOrderBy(field);
    } else {
      setOrderBy(sorter.field);
    }
  };

  useEffect(() => {
    const getMemberTableDetails = async () => {
      try {
        setIsLoading(true);
        if(!searchTerm && !orderBy) {
          const projectDetails = await projectService.getProject(selectedProjectDetails.id);
          dispatch(updateProjectDetails(projectDetails.data.data.project));
        }
        const { data } = await projectService.getMemberInformation(
          selectedProjectDetails.id,
          searchTerm,
          orderBy
        );
        setIsLoading(false);
        setMemberListing(data.data);
      } catch (error: any) {
        setIsLoading(false);
        openNotificationWithIcon("", `${error.response.data.message}`, "error");
      }
    };
    if (Object.keys(selectedProjectDetails).length) getMemberTableDetails();
  }, [searchTerm, orderBy]);

  const handleRowClick = (record: MemberDetails) => {
    dispatch(updateSelectedCaseId(record.id));
    navigate(`/case-details/${record.id}`);
  };

  const handleChange = (event: any, type?: string) => {
    setSearchText(event.target.value);
    if (event.target.value == "") {
      setSearchTerm("");
    }
  };

  return (
    <div>
      <div className="flex jcsb">
        <Search
          className="record-search m-b"
          placeholder="Search by Member Name, Member ID, Case Manager Name, PCP Name"
          enterButton={isLoading ? <Loading tip="" /> : <Button icon={<SearchIcon />} />}
          value={searchText}
          onChange={(e) => handleChange(e)}
          onSearch={(e) => handleSearch(e)}
          disabled={isLoading}
          style={{ width: "40em" }}
        />
        <AppPagination
          className="tr m-b paginationDiv"
          {...{
            showSizeChanger: true,
            current: page,
            total: 20,
            pageSize,
            pageSizeOptions,
            onChange: onPaginationChange,
          }}
        />
      </div>
      <div className="member-table" style={{ display: "flex" }}>
        <Table
          rowClassName={"cursor-pointer"}
          columns={getColumns()}
          pagination={false}
          dataSource={memberListing}
          loading={isLoading}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
          })}
          onChange={handleTableChange}
        />
      </div>
    </div>
  );
};

export default MembersListing;
