import React, { useState } from "react";
import { Tabs } from "antd";
import PrecallReview from "../precall-review/PrecallReview";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import CarePlan from "../care-plan/CarePlan";
import CallSummary from "../call-summary/CallSummary";

const { TabPane } = Tabs;

const CaseDetailsTabs: React.FC = () => {
  const { selectedCaseId } = useSelector((state: RootState) => state.projectDetails);
  const [activeTab, setActiveTab] = useState<string>("precall");
  const [carePlanGenerated, setCarePlanGenerated] = useState<boolean | undefined>(false)

  const handleTabChange = (key: string, newCarePlanGenerated?: boolean) => {
    setActiveTab(key);
    setCarePlanGenerated(newCarePlanGenerated);
  };

  return (
    <Tabs className="case-details-tab" activeKey={activeTab} onChange={handleTabChange}>
      <TabPane tab="Precall Review" key="precall">
        <PrecallReview selectedCaseId={selectedCaseId} />
      </TabPane>
      <TabPane tab="Call Summary" key="summary">
        <div>
          <CallSummary selectedCaseId={selectedCaseId} navigateToNextTab={handleTabChange} />
        </div>
      </TabPane>
      <TabPane tab="Care Plan" key="carePlan">
        <div>
          <CarePlan selectedCaseId={selectedCaseId} activeTab={activeTab} />
        </div>
      </TabPane>
    </Tabs>
  );
};

export default CaseDetailsTabs;
