import React, { useEffect, useState } from "react";
import "./care-plan.scss";
import { Button, Divider, Modal, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState, openModal, updateDeleteConfirmation, updateGeneratedDarNote } from "../../store";
import { MODAL_TYPE, getLocalDateTimeStringFromISO, openNotificationWithIcon } from "../../utils";
import { PdfViewer } from "../pdf-viewer";
import { projectService } from "../../api/project";
import {
  CloseOutlined,
  ExclamationCircleOutlined,
  FullscreenOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import { Loading } from "../Loading";
import { downloadPDF } from "../precall-review/helper";
import { DeleteIcon } from "../../assets/icons";

interface Props {
  selectedCaseId: number;
  activeTab: string;
}

const CarePlan = ({ selectedCaseId, activeTab }: Props) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [carePlanVersion, setCarePlanVersions] = useState<any>([]);
  const { generatedDarNote } = useSelector((state: RootState) => state.projectDetails);
  const { isDeleteConfirmed } = useSelector((state: RootState) => state.ui);
  const [currentPage, setCurrentPage] = useState(1);
  const [showFileName, setShowFileName] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [showFile, setShowFile] = useState(false);
  const [carePlanPdf, setCarePlanPdf] = useState("");
  const [loadingObj, setLoadingObj] = useState({ updatingCarePlan: false });
  const [isCarePlanUpdated, setIsPlanCareUpdated] = useState(false);
  const [carePlanStatus, setCarePlanStatus] = useState("");
  const [carePlanId, setCarePlanId] = useState(-1);
  const [refreshTable, setRefreshTable] = useState(false);
  const [deleteDocument, setDeleteDocument] = useState("");

  useEffect(() => {
    setCarePlanPdf("");
  }, [activeTab]);

  const fetchCarePlan = async () => {
    try {
      if(activeTab === "carePlan"){
        setIsLoading(true);
        const { data } = await projectService.fetchReviewCarePlan(selectedCaseId);
        setCarePlanVersions(
          data.sort(
            (a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          )
        );
        dispatch(updateGeneratedDarNote(data.darNote.darNote));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCarePlan();
  }, [isCarePlanUpdated, activeTab, refreshTable]);

  useEffect(() => {
    const handleDeleteDocuments = async () => {
      await projectService.deleteCarePlan(deleteDocument);
      fetchCarePlan();
      setDeleteDocument("");
    };
    if (deleteDocument !== "" && isDeleteConfirmed) {
      handleDeleteDocuments();
      dispatch(updateDeleteConfirmation(false));
    }
  }, [deleteDocument, dispatch, isDeleteConfirmed]);

  // useEffect(() => {
  //   const handleShowFile = async () => {
  //     const { data } = await projectService.handleShowFile(
  //       carePlanVersion[0]?.name,
  //       selectedCaseId
  //     );
  //     setCarePlanPdf(data.data.signedUrl);
  //   };
  //   carePlanVersion[0] && handleShowFile();
  // }, [carePlanVersion]);

  const getClass = (value: string) => {
    switch (value) {
      case "PENDING":
      case "DRAFT":
        return "#ff9800";
      default:
        return "#44c658";
    }
  };

  const handleClose = () => {
    setShowFileName("");
    setShowFile(false);
  };

  useEffect(() => {
    const getSignedurl = async () => {
      setIsLoading(true);
      try {
        const { data } = await projectService.handleShowFile(showFileName, selectedCaseId);
        setFileUrl(data.data.signedUrl);
      } catch (err: any) {
        openNotificationWithIcon("", err.response.data.message, "error");
        handleClose();
      } finally {
        setIsLoading(false);
      }
    };
    if (showFile && showFileName) getSignedurl();
  }, [showFile, showFileName]);

  const getColumns = () => [
    {
      title: "Document Name",
      key: "documentName",
      dataIndex: ["document", "name"],
      render: (text: any, value: any) => (
        <p className="link-color">{value.name.substring(value.name.lastIndexOf("/") + 1)}</p>
      ),
    },
    {
      title: "Date Created",
      key: "dateCreated",
      dataIndex: ["document", "createdAt"],
      render: (text: any, value: any) => <p>{getLocalDateTimeStringFromISO(value.createdAt)}</p>,
    },
    {
      title: "Approved By",
      key: "approvedBy",
      dataIndex: "approvedBy",
      // render: (text: any, value: any) => (
      //   <div
      //     className="link-color cursor-pointer"
      //     onClick={() => handleShowFile(value.name.substring(value.name.lastIndexOf("/") + 1))}
      //   >
      //     Preview
      //   </div>
      // ),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (text: any, value: any) => (
        <>
          {value.status && (
            <div>
              <div className="status" style={{ color: getClass(value.status) }}>
                {value.status === "DRAFT" ? (
                  <div className="flex">
                    <p style={{ color: getClass(value.status) }}>{value.status}</p>
                    <ExclamationCircleOutlined />
                  </div>
                ) : (
                  <p style={{ color: getClass(value.status) }}>{value.status}</p>
                )}{" "}
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text: any, value: any) => (
        <a className="p-10">
          <DeleteIcon
            width={18}
            height={18}
          />
        </a>
      ),
      className: "table-action",
    },
  ];

  const handleApproveChanges = async () => {
    setLoadingObj((prev) => ({ ...prev, updatingCarePlan: true }));
    try {
      const { data } = await projectService.approveCarePlanChanges(selectedCaseId, carePlanId);
      console.log(data); // to ignore TS error
      dispatch(openModal(MODAL_TYPE.UPDATE_CARE_PLAN));
      setIsPlanCareUpdated(true);
      setCarePlanPdf("");
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingObj((prev) => ({ ...prev, updatingCarePlan: false }));
    }
  };

  const handleFileDownload = async () => {
    await downloadPDF(fileUrl, `${showFileName}_${new Date().getTime()}.pdf`);
  };

  const handleRowClick = async (record: any, event: any) => {
    if (event.target.tagName === "svg") {
      if (record.id) {
        setDeleteDocument(record.id);
        dispatch(openModal(MODAL_TYPE.DELETE_CONFIRMATION));
      }
    } else {
      const { data } = await projectService.handleShowFile(record.name, selectedCaseId);
      setCarePlanPdf(data.data.signedUrl);
      setCarePlanStatus(record.status);
      setCarePlanId(record.id);
    }
  };

  return (
    <div>
      <div className="care-plan-body">
        {carePlanPdf === "" && (
          <div className="dar-notes">
            <div className="care-plan-versions">
              <div className="flex jcsb">
                <h3 className="title">Care Plan Versions</h3>
                <Button className="outline" onClick={() => setRefreshTable(!refreshTable)}>
                  Refresh
                </Button>
              </div>
              <Table
                rowClassName={"cursor-pointer"}
                columns={getColumns()}
                pagination={false}
                loading={isLoading}
                dataSource={carePlanVersion}
                onRow={(record) => ({
                  onClick: (event) => handleRowClick(record, event),
                })}
              />
            </div>
          </div>
        )}
        {carePlanPdf && carePlanStatus === "DRAFT" && (
          <div style={{ display: "flex", gap: "20px" }}>
            <div className="pdf-holder">
              <div className="flex gp">
                <LeftOutlined onClick={() => setCarePlanPdf("")} />
                <h2>Care Plan</h2>
              </div>
              {carePlanPdf ? (
                <>
                  <FullscreenOutlined
                    className="expand-icon cursor-pointer"
                    style={{ fontSize: "20px" }}
                    onClick={() => {
                      setShowFile(true);
                      setFileUrl(carePlanPdf);
                    }}
                  />
                  <PdfViewer
                    pdf={carePlanPdf}
                    displayPagination
                    setCurrPage={setCurrentPage}
                    currPage={currentPage}
                  />
                </>
              ) : (
                <Loading />
              )}
            </div>
            <div style={{ width: "45%" }}>
              <div className="title">
                <h3>DAR Note</h3>
              </div>
              <p
                style={{ overflow: "auto", maxHeight: "510px", whiteSpace: "pre-line" }}
                dangerouslySetInnerHTML={{ __html: generatedDarNote }}
              />
              <div className="button-container">
                <Button
                  className="outline"
                  onClick={handleApproveChanges}
                  loading={loadingObj.updatingCarePlan}
                >
                  Approve Changes
                </Button>
              </div>
            </div>
          </div>
        )}
        {carePlanPdf && carePlanStatus !== "DRAFT" && (
          <div style={{ width: "100%" }}>
            <div className="flex gp">
              <LeftOutlined onClick={() => setCarePlanPdf("")} />
              <h2>Care Plan</h2>
            </div>
            <FullscreenOutlined
              className="expand-icon cursor-pointer"
              style={{ fontSize: "20px" }}
              onClick={() => {
                setShowFile(true);
                setFileUrl(carePlanPdf);
              }}
            />
            <PdfViewer
              pdf={carePlanPdf}
              displayPagination
              setCurrPage={setCurrentPage}
              currPage={currentPage}
            />
          </div>
        )}
      </div>
      {showFile && (
        <Modal
          className="autonomizeModal"
          centered
          width={1300}
          open={showFile}
          footer={null}
          title={"Preview"}
          closeIcon={<CloseOutlined onClick={handleClose} />}
        >
          <div className="addClient-title no-bd-bt">
            <h1 className="mb-0">Preview</h1>
          </div>
          {isLoading ? (
            <Loading />
          ) : (
            <div>
              <div className="addClient-content no-pd-tp">
                <PdfViewer
                  pdf={fileUrl}
                  displayPagination
                  setCurrPage={setCurrentPage}
                  currPage={currentPage}
                  pageInfo={{scale: 2}}
                />
              </div>
              <div className="flex jce p-10">
                <Button className="fill" onClick={handleFileDownload} disabled={fileUrl === ""}>
                  Download
                </Button>
              </div>
            </div>
          )}
        </Modal>
      )}
    </div>
  );
};

export default CarePlan;
