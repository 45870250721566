import React, { useEffect, useState } from "react";
import { AddContainerProps } from ".";
import { Button, Modal } from "antd";
import { openNotificationWithIcon } from "../../../utils";
import "./updateCarePlan.scss";
import { SuccessIcon } from "../../../assets/icons";

export const UpdateCarePlan = ({ handleClose, visibility }: AddContainerProps) => {
  return (
    <Modal
      className="autonomizeModal"
      centered
      width={587}
      open={visibility}
      footer={null}
      closable={false}
    >
      <div className="updateCarePlan-title no-bd-bt">
        <h1>Update Care Plan</h1>
      </div>
      <div className="updateCarePlan-content">
        <SuccessIcon className="updateCarePlan-content-icon"/>
        <span className="updateCarePlan-content-title">Success</span>
        <span className="updateCarePlan-content-subtitle">Care Plan update was successful!</span>
        <Button onClick={handleClose} className="fill  updateCarePlan-content-button">
          Close
        </Button>
      </div>
    </Modal>
  );
};
