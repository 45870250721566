import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { autonomizeFullLogo } from "../assets/images";
import { useAuthUser } from "../hooks";
import { PAGE_URL } from "../utils";

export const GuestLayout = () => {
  const navigate = useNavigate();
  const { accessToken, roleCode } = useAuthUser();

  useEffect(() => {
    if (accessToken && accessToken.length > 0) {
      navigate(PAGE_URL.DASHBOARD);
    }
  }, [accessToken, navigate, roleCode]);

  return (
    <div className="user-page flex aic jcc">
      <div className="user-page-container flex no-wrap jcc aic">
        <div className="brand-logo">
          <img className="brand-logo-img m-b" src={autonomizeFullLogo} alt="Autonomize Logo" />
          <h1 className="brand-title tr">
            C<span className="brand-title-highlight">a</span>re
            M<span className="brand-title-highlight">a</span>nagement
            C<span className="brand-title-highlight">o</span>pilot
          </h1>
        </div>
        <Outlet />
      </div>
    </div>
  );
};
