import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useProject } from "../../hooks";
import { RootState, openModal, updateDeleteConfirmation, updateProjectDetails } from "../../store";
import { MODAL_TYPE, PAGE_URL, getLocalDateTimeStringFromISO } from "../../utils";
import { Button, Card, Table } from "antd";
import { Loading } from "../../components";
import "./project-container.scss";
import ProjectIcon from "../../components/project-icon/ProjectIcon";
import { updateCanFetchContainers } from "../../store/file-folder-upload/slice";
import { plusIcon } from "../../assets/images";
import { ProjectActions } from "./ProjectActions";

interface Props {
  setSelectedProjectId: React.Dispatch<React.SetStateAction<number>>;
  setSelectedProjectName: React.Dispatch<React.SetStateAction<string>>;
  setIsPriorAuthProject: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProjectsTable = ({
  setSelectedProjectId,
  setSelectedProjectName,
  setIsPriorAuthProject,
}: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { deleteProject, fetchProjects, loading, projects } = useProject();
  const isDeleteConfirmed = useSelector((state: RootState) => state.ui.isDeleteConfirmed);
  const { canFetchContainers } = useSelector((state: RootState) => state.fileFolderUpload);
  const [projectToBeDeleted, setProjectToBeDeleted] = useState(-1);
  const sortedProjects = projects.sort((a, b) => b.id - a.id);

  useEffect(() => {
    dispatch(updateCanFetchContainers(true));
  }, [dispatch]);

  useEffect(() => {
    if (canFetchContainers) {
      fetchProjects();
      dispatch(updateCanFetchContainers(false));
    }
  }, [canFetchContainers, dispatch, fetchProjects]);

  useEffect(() => {
    const deleteProj = async () => {
      await deleteProject(projectToBeDeleted);
      dispatch(updateDeleteConfirmation(false));
      dispatch(updateCanFetchContainers(true));
    };
    if (isDeleteConfirmed) {
      deleteProj();
    }
  }, [deleteProject, dispatch, isDeleteConfirmed, projectToBeDeleted]);

  //Function taking project info and based on that rendering a card
  const renderProjectCard = (project: IProject) => (
    <Card
      className="cursor-pointer p-20"
      onClick={() => {
        setSelectedProjectId(project.id);
        setSelectedProjectName(project.name);
        dispatch(updateProjectDetails(project));
        navigate(`/projects/${project.id}`);
      }}
      style={{ height: "205px" }}
    >
      <div className="flex jcsb">
        <p className="project-card-title m-b">{project.name}</p>
        <ProjectActions project={project} setProjectToBeDeleted={setProjectToBeDeleted} />
      </div>
      <div
        className="cursor-pointer project-card-footer flex flex-column"
        // onClick={() => navigate(`${PAGE_URL.PROJECT_DETAIL}/${project.Id}`)}
      >
        <div className="project-card-view-dis cursor-pointer">
          <h5 title={project.description}>Project Description</h5>
          <p dangerouslySetInnerHTML={{ __html: project.description }} />
        </div>
      </div>

      <div className="flex jcsb aic pt-20 mt-auto">
        <p>
          <span className="highlight">Created on </span>
          {getLocalDateTimeStringFromISO(project.createdAt)}
        </p>
        <p>
          <span className="highlight">Updated on </span>
          {getLocalDateTimeStringFromISO(project.updatedAt)}
        </p>
      </div>
    </Card>
  );

  return (
    <div style={{ height: "100vh", padding: "0px 20px" }}>
      <div className="flex jce p-block">
        <Button
          className="fill"
          onClick={() => {
            dispatch(openModal(MODAL_TYPE.ADD_PROJECT));
          }}
        >
          Create Project
        </Button>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div className="project-card-grid">
          {sortedProjects.map((project, index) => (
            <div key={`${index}`}>{renderProjectCard(project)}</div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProjectsTable;
