import React, { useState } from "react";
import "./caseDetails.scss";
import MemberProfile from "../../components/case-details/MemberProfile";
import CaseDetailsTabs from "../../components/case-details-tabs/CaseDetailsTabs";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

const CaseDetailContainer = () => {
  const [isMemberProfileVisible, setIsMemberProfileVisible] = useState(true);

  const toggleMemberProfileVisibility = () => {
    setIsMemberProfileVisible(!isMemberProfileVisible);
  };

  return (
    <div className="main">
      <div className="member-details-contianer">
        <div className="member-details">
          <MemberProfile isMemberProfileVisible={isMemberProfileVisible} />
          <div
            className={`member-details-contianer-toggle ${
              isMemberProfileVisible ? "" : "member-details-contianer-toggle-short"
            }`}
            onClick={toggleMemberProfileVisibility}
            title="Member Profile"
          >
            {!isMemberProfileVisible ? <UpOutlined /> : <DownOutlined />}
          </div>
        </div>
      </div>
      <div className="second-container">
        <CaseDetailsTabs />
      </div>
    </div>
  );
};

export default CaseDetailContainer;
