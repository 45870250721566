export enum THEME {
  LIGHT = "light-theme",
  DARK = "dark-theme",
}

export enum DOCUMENT_STATUS {
  PROCESSING = "PROCESSING",
  READY = "READY",
  FAILED = "FAILED",
  READY_WITH_SUMMARY = "READY_WITH_SUMMARY",
}

export enum REVIEW_STATUS {
  NOT_REVIEWED = "NOT_REVIEWED",
  APPROVED = "APPROVED",
  DENIED = "DENIED",
  REQUESTED_FOR_MORE_INFO = "REQUESTED_MORE_INFO",
  FORWARDED_FOR_REVIEW = "FORWARDED_FOR_REVIEW",
}

export enum CASE_STATUS {
  OPEN = "Open",
  CLOSED = "Closed"
}

export enum STATUS_CODE {
  FORBIDDEN = 403,
}

export enum MODAL_TYPE {
  ADD_EDIT_PROJECT = "addEditProject",
  DELETE_CONFIRMATION = "deleteConfirmation",
  EDIT_PROFILE = "editProfile",
  FORGOT_PASSWORD = "forgotPassword",
  ADD_AND_SIGN_UP_USER = "addAndSignUpUser",
  APPROVE_CONFIRMATION = "approveConfirmation",
  FEEDBACK = "feedback",
  ADD_EDIT_FOLDER = "addEditFolder",
  VIEW_FULL_SOURCE = "viewFullSource",
  DATA_SOURCE = "dataSource",
  EDIT_FILE = "editFile",
  ADD_PROJECT = "addProject",
  NO_PROJECTS_WARNING = "noProjectsWarning",
  LICENSE_KEY = "licenseKey",
  CONNECT_TO_DATA_SOURCE = "connectToDataSource",
  EDIT_PROJECT = "editProject",
  UPDATE_CARE_PLAN = "updateCarePlan"
}

export enum USER_ROLES_CODE {
  ADMIN = "SUPER_ADMIN",
  DATA_SCIENTIST = "DATA_SCIENTIST",
  BUSINESS_USER = "BUSINESS_USER",
}