import React from "react";
import { openNotificationWithIcon } from "../../utils";

export const getReviewClass = (value: string) => {
  switch (value.toLocaleLowerCase()) {
    case "processing":
      return "#ff9800";
    case "completed":
      return "#44c658";
  }
};

export const getColumnsClaims = () => [
  {
    title: "Claim ID",
    key: "claimId",
    dataIndex: "claimId",
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    render: (text: any, value: any) => (
      <div className="status" style={{ color: getReviewClass(value.status) }}>
        <p style={{ color: getReviewClass(value.status) }}>{value.status}</p>{" "}
      </div>
    ),
  },
  {
    title: "Provider",
    key: "provider",
    dataIndex: "provider",
  },
  {
    title: "Billed Amount",
    dataIndex: "billedAmount",
    key: "billedAmount",
  },
  {
    title: "Eligible Amount",
    dataIndex: "eligibleAmount",
    key: "eligibleAmount",
  },
];


export const downloadPDF = async (url: string, filename: string) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = filename;
    link.click();
    URL.revokeObjectURL(blobUrl);
  } catch (error) {
    openNotificationWithIcon("", "Downlaod Failed", "error");
  }
};